/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
// eslint-disable-next-line react/button-has-type
import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Travels = () => {
  const { travels } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [locationId, setLocationId] = useState(null);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const handleLocationClick = id => {
    setLocationId(id);
  };

  return (
    <section id="travels">
      <Container>
        <Title title="Travels" />
        <h3 style={{ flex: 1 }}>Click on a location!</h3>
        {travels.map(travel => {
          const { id, name } = travel;
          return (
            <Button
              style={{ margin: '20px' }}
              variant="primary"
              size="lg"
              onClick={() => handleLocationClick(id)}
            >
              {name}
            </Button>
          );
        })}
        <div className="travels-wrapper">
          {travels.map(travel => {
            const { id, name, imgs } = travel;
            if (id === locationId) {
              return (
                <Row key={id}>
                  {imgs.map(image => {
                    return (
                      <Col lg={6} sm={12}>
                        <Fade
                          right={isDesktop}
                          bottom={isMobile}
                          duration={700}
                          delay={500}
                          distance="30px"
                        >
                          <div className="travel-wrapper__image">
                            <a
                              href={null}
                              target="_blank"
                              aria-label="Project Link"
                              rel="noopener noreferrer"
                            >
                              <Tilt
                                options={{
                                  reverse: false,
                                  max: 8,
                                  perspective: 1000,
                                  scale: 1,
                                  speed: 300,
                                  transition: true,
                                  axis: null,
                                  reset: true,
                                  easing: 'cubic-bezier(.03,.98,.52,.99)',
                                }}
                              >
                                <div data-tilt className="thumbnail rounded" style={{ margin: 20 }}>
                                  <ProjectImg alt={name} filename={image} />
                                </div>
                              </Tilt>
                            </a>
                          </div>
                        </Fade>
                      </Col>
                    );
                  })}
                </Row>
              );
            }
            return null;
          })}
        </div>
      </Container>
    </section>
  );
};

export default Travels;
