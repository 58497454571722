import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import StarIcon from '@material-ui/icons/Star';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PublicIcon from '@material-ui/icons/Public';
import Title from '../Title/Title';

const Work = () => {
  return (
    <div style={{ marginTop: 50, marginBottom: 75 }}>
      <Title title="Work Experience & Education" />
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#007bff', color: '#fff', fontWeight: 'bold' }}
          contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
          date="2021 - PRESENT"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Technical Team Lead/Software Engineer Manager @Vail Systems
          </h3>
          <p />
          <h4 className="vertical-timeline-element-subtitle">Chicago, IL</h4>
          <p />
          <h4> NextJS | NestJS | GraphQL | AWS </h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#007bff', color: '#fff', fontWeight: 'bold' }}
          contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
          date="2021 - PRESENT"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Software Engineer 2 @Vail Systems</h3>
          <p />
          <h4 className="vertical-timeline-element-subtitle">Chicago, IL</h4>
          <p />
          <h4>ReactJS | NextJS | NodeJS | NestJS | GraphQL | AWS </h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#007bff', color: '#fff', fontWeight: 'bold' }}
          contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
          date="2019 - 2021"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Software Engineer @Vail Systems</h3>
          <p />
          <h4 className="vertical-timeline-element-subtitle">Chicago, IL</h4>
          <p />
          <h4>React Native | ReactJS | NodeJS | Ruby on Rails | Docker | SQL</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#007bff', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
          date="FALL 2018"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">BS in Computer Science @UIC</h3>
          <p />
          <h4 className="vertical-timeline-element-subtitle">Chicago, IL</h4>
          <p />
          <h4>C/C++ | C# | Java | SQL </h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#007bff', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
          date="SUMMER 2018"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<LaptopMacIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Engineer Intern @Vail Systems
          </h3>
          <p />
          <h4 className="vertical-timeline-element-subtitle">Chicago, IL</h4>
          <p />
          <h4>ReactJS | NodeJS | MongoDB </h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#007bff', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
          date="SPRING 2018"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<PublicIcon />}
        >
          <h3 className="vertical-timeline-element-title">Full Stack Developer Intern @NASA</h3>
          <p />
          <h4 className="vertical-timeline-element-subtitle">Cleveland, OH</h4>
          <p />
          <h4>Angular | NodeJS | MongoDB | SQL</h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: '#007bff', color: '#fff' }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  );
};

export default Work;
