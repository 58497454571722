import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Akun Taniguchi',
  description: 'Welcome to my website!',
};

// HERO DATA
export const heroData = {
  title: `Hi, I'm `,
  name: 'Akun Taniguchi',
  subtitle: `I'm a Software Engineer Manager`,
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpeg',
  paragraphOne: `I'm a developer raised in the suburbs of Chicago.`,
  paragraphTwo: 'Currently working on web apps using React/Next.js + Node/Nest.js + GraphQL + AWS',
  paragraphThree:
    'Outside of the software world, I enjoy traveling, reading, video editing, and playing the piano.',
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// TRAVELS DATA
export const travelsData = [
  {
    id: uuidv1(),
    imgs: ['bulgaria.JPG', 'bulgaria4.JPG', 'bulgaria1.JPG', 'bulgaria3.JPG'],
    name: 'BULGARIA',
  },
  {
    id: uuidv1(),
    imgs: ['greece1.JPG', 'greece2.JPG'],
    name: 'GREECE',
  },
  {
    id: uuidv1(),
    imgs: [
      'iceland4.JPG',
      'iceland2.JPG',
      'iceland.JPG',
      'iceland3.JPG',
      'iceland5.JPG',
      'iceland6.JPG',
      'iceland7.JPG',
      'iceland8.JPG',
      'iceland11.JPG',
      'iceland9.JPG',
      'iceland10.JPG',
    ],
    name: 'ICELAND',
  },
  {
    id: uuidv1(),
    imgs: ['aspen.JPG', 'aspen1.JPG', 'aspen2.JPG'],
    name: 'ASPEN, CO',
  },
  {
    id: uuidv1(),
    imgs: ['nashville.JPG', 'nashville1.JPG'],
    name: 'NASHVILLE, TN',
  },
  {
    id: uuidv1(),
    imgs: ['neworleans.JPG', 'neworleans2.JPG', 'neworleans1.JPG', 'neworleans3.JPG'],
    name: 'NEW ORLEANS, LA',
  },
  {
    id: uuidv1(),
    imgs: [
      'banff4.JPG',
      'banff.JPG',
      'banff3.JPG',
      'banff2.JPG',
      'banff1.JPG',
      'banff5.JPG',
      'banff7.JPG',
      'banff6.JPG',
    ],
    name: 'BANFF, CANADA',
  },
  {
    id: uuidv1(),
    imgs: ['telluride.JPG', 'telluride1.JPG', 'telluride2.JPG'],
    name: 'TELLURIDE, CO',
  },
  {
    id: uuidv1(),
    imgs: ['orlando1.JPG', 'orlando2.JPG', 'orlando3.JPG', 'orlando.JPG'],
    name: 'ORLANDO, FL',
  },
  {
    id: uuidv1(),
    imgs: [
      'austin1.jpeg',
      'austin2.jpeg',
      'austin3.jpeg',
      'austin4.jpeg',
      'austin5.jpeg',
      'austin6.jpeg',
      'austin7.jpeg',
      'austin8.jpeg',
      'austin9.jpeg',
      'austin10.jpeg',
      'austin11.jpeg',
      'austin12.jpeg',
    ],
    name: 'AUSTIN, TX',
  },
  {
    id: uuidv1(),
    imgs: [
      'az2.jpeg',
      'az3.jpeg',
      'az4.jpeg',
      'az5.jpeg',
      'az6.jpeg',
      'az7.jpeg',
      'az8.jpeg',
      'az9.jpeg',
      'az10.jpeg',
      'az11.jpeg',
      'az12.jpeg',
      'az13.jpeg',
      'az14.jpeg',
      'az15.jpeg',
      'az16.jpeg',
      'az17.jpeg',
      'az18.jpeg',
    ],
    name: 'ARIZONA',
  },
];

// CONTACT DATA
export const contactData = {
  cta: `Let's talk!`,
  btn: 'Email',
  email: 'akuntaniguchi@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'instagram',
      url: 'https://www.instagram.com/akunbtaniguchi',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/akihikotaniguchi',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/akuntaniguchi',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
